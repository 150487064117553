import React from "react";
import isColorLight from "./idColorLight";
import styles from './Cross.module.css';

function CellElement(props) {
    //console.log(props);
    const { elementData } = props;
    const { blank, key, liter, color, id, begin, cross_word_number, answer, counter, updateCounter} = elementData;

    const handleInput = (e) => {
        if(e.currentTarget.value === liter) {
            e.currentTarget.style.backgroundColor = 'black';
            e.currentTarget.style.color = '#ffc107';
            props.updateCounter(1,0)
        }
        else if(e.currentTarget.value !== '')
            props.updateCounter(0,1)
    }

    if (begin) {
        return (
            <div className={`${styles.cell} ${styles.unused}`} style={{color: "white"}} >
                {cross_word_number}
            </div>
        );
    }

    if (blank) return <div className={`${styles.cell} ${styles.unused}`} maxlength="1"/>;

    if (props.answer) {
        return (
            <div className={styles.cell}>
                <input id={styles.crossword_cell} type="text" maxLength="1" readOnly value={liter}
                       style={{
                           backgroundColor: "black",
                           color: "#ffc107"}}
                />
            </div>
        );
    }

    else {
        return (
            <div className={styles.cell}>
                <input id={styles.crossword_cell} type="text" maxLength="1"
                       style={{
                           backgroundColor:color,
                           color: isColorLight(color) ? "black" : "white"}}
                       onChange={handleInput}
                />
            </div>
        );
    }
}


export default CellElement;